import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowLastDiffPipe } from '../pipes/show-last-diff.pipe';
import { TimeAgoPipe } from '../pipes/time-ago.pipe';
import { NgChartsModule } from 'ng2-charts';
import { default as Annotation } from 'chartjs-plugin-annotation';

@NgModule({
  declarations: [TimeAgoPipe, ShowLastDiffPipe],
  imports: [
    CommonModule,
    NgChartsModule.forRoot({
      defaults: {},
      plugins: [Annotation],
    }),
  ],
  exports: [TimeAgoPipe, ShowLastDiffPipe, NgChartsModule],
})
export class SharedModule {}
