import { take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { AuthService, Company } from '../auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import firebase from 'firebase/app';

@Component({
  selector: 'app-login',
  templateUrl: './login-component.html',
  styleUrls: [
    '../../../vendor/styles/pages/authentication.scss',
    '../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
  ]
})
export class LoginComponent implements OnInit {
  myForm: FormGroup;
  error: any;
  loading = false;

  hospitalControl = new FormControl('');

  constructor(
    private appService: AppService,
    private fb: FormBuilder,
    public auth: AuthService,
    private router: Router
  ) {

    // console.log('LoginComponent constructor');
    this.appService.pageTitle = 'Login';

    // Cria o modelo do formulario (grupos contém controls)
    // myForm.value será { email: 'xx@xx.com', password: 'senha' }
    this.myForm = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email]), // requerido e necessita ser email
      password: this.fb.control('', [
        Validators.required,
        Validators.minLength(6)
      ]), // requerido e pelo menos 6 digitos
    });
  }

  ngOnInit(): void {

  }

  /**
   * Executa ao submeter o formulário
   * Faz Login do Usuário, particularmente para este aplicativo de glicemia o tipo de persistencia é Local, logo
   * é possivel fechar o navegador e reabrir e permanecer com o usuário logado. isto agiliza o serviço.
   */
  async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      await this.auth.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      const credential = await this.auth.emailLogin(this.myForm.value.email, this.myForm.value.password);
      await this.onLoggedIn(credential);
      this.router.navigate(['']);
      this.loading = false;
    } catch (error) {
      this.error = {
        errorCode: error.code,
        errorMessage: error.message,
        errorMsgPt: this.getMessage(error.code)
      };
      this.loading = false;
      console.error(this.error);
      this.auth.signOut();
    }
  }

  /**
   * Ações realizadas após o login do usuário.
   * Em paralelo o serviço AuthService já recuperou  os dados extras do firestore, logo poderá ser acesso aqui pelo serviço.
   */
  async onLoggedIn(credential: firebase.auth.UserCredential): Promise<Company> {

    if (!credential.user) {
      throw new Error('Erro com credenciais');
    }

    const user = await this.auth.dxmedUser$.pipe(take(1)).toPromise();

    if (user.type !== 'service') {
      throw ({ code: 'auth/service-account' });
    }

    if (!user.companies || user.companies.length === 0) {
      throw ({ code: 'auth/need-company' });
    }

    return this.auth.connectToCompany(this.auth.dxmedUser.companies[0]);
  }

  /**
   * Retorna as mensagens de erros mais comuns de login (em pt_BR);
   * Elas são usadas no template para indicar tais.
   */
  private getMessage(errorCode: string): string {
    switch (errorCode) {
      case 'auth/invalid-email':
        return 'E-email inválido';
      case 'auth/user-disabled':
        return 'Usuário desabilitado.';
      case 'auth/user-not-found':
        return 'Usuário ou email inexistente.';
      case 'auth/wrong-password':
        return 'Senha inválida';
      case 'auth/network-request-failed':
        return 'Ocorreu um erro no login, verifique sua internet e tente mais tarde.';
      case 'auth/service-account':
        return 'Não é possivel entrar com esta conta';
      case 'auth/need-company':
        return 'Usuário sem acesso a hospitais';
      default:
        return 'Ocorreu um erro';
    }
  }
}

