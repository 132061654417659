import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirestoreHelper } from 'src/app/firestore-helper';
import { Router } from '@angular/router';
import { Observable, of, combineLatest, BehaviorSubject, Subject } from 'rxjs';
import { map, switchMap, pluck, tap, filter, take } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import firebase from 'firebase/app';

export interface DxmedUser {
  id: string;
  displayName: string;
  email: string;
  gender: string;
  companies: string[];
  lastHospital?: string;
  type?: string;
  phoneNumber?: string;
  title?: string;
}

export interface Claims {
  owner?: boolean;
  moderator?: boolean;
}
export interface Company {
  id: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // Observable User States
  authState$: Observable<firebase.User | null>;
  user$: Observable<firebase.User | null>;
  dxmedUser$: Observable<DxmedUser>;
  hasUser$: Observable<boolean>;

  // Static User States
  user: firebase.User;
  dxmedUser: DxmedUser;
  currentCompany: Company;

  claims: Claims = {};

  userAvatar$ = new BehaviorSubject<string>('assets/svg/doctor3.svg');

  constructor(
    private afAuth: AngularFireAuth,
    private fns: AngularFireFunctions,
    private db: FirestoreHelper,
    private router: Router,
  ) {

    // console.log('Auth service constructor');

    /**
     * Escuta a Observable de autenticação do usuário
     * Se este estiver logado pega informações adicionais na coleção "companies" e returna
     * uma nova Observable com estes ou null se não existir
     */

    this.hasUser$ = this.afAuth.authState.pipe(map(user => !!user));
    this.user$ = this.afAuth.user;
    this.dxmedUser$ = this.user$.pipe(
      filter(user => !!user),
      switchMap(user => this.getUserData(user!.uid))
    );

    this.user$.pipe(
      filter(user => !!user),
      tap(user => this.user = user!),
      switchMap((user: any) => this.getUserData(user.uid))
    ).subscribe(dxmedUser => {
      this.dxmedUser = dxmedUser;
    });

    //     this.claims = {};
    //     this.getCustomClaims(user);
    //     this.user = user;
    //     this.userAvatar$.next(this.getAvatar(user));
    //   } else {
    //     this.user = null;
    //   }
    // });
    // this.userWithData$ = this.afAuth.user.pipe(switchMap(user => (user) ? this.getUserData(user.uid) : of(null)));
  }

  async getCustomClaims(user: firebase.User): Promise<void> {
    const result = await user.getIdTokenResult();
    if (result.claims.owner) {
      this.claims.owner = true;
    }
    if (result.claims.moderator) {
      this.claims.moderator = true;
    }
  }

  getCompaniesInArray(companies: string[]): Observable<any[]> {
    if (companies.length > 10) {
      throw new Error('Número de companias não pode ser maior que 10');
    }
    return this.db.col$('companies', ref => ref.where('id', 'in', companies));
  }

  async connectToCompany(companyId: string): Promise<Company> {
    if (!this.user?.uid) {
      throw new Error('Sem usuário logado');
    }
    const company = await this.db.doc$<Company>(`companies/${companyId}`).pipe(take(1)).toPromise();
    if (!company) {
      throw new Error('Esta companhia não existe');
    }
    localStorage.setItem('company', company.id);
    this.currentCompany = company;
    return company;
  }

  getUserData(uid: string): Observable<DxmedUser> {
    return this.db.doc$<DxmedUser>(`users/${uid}`);
  }

  getCompany(companyId: string): Observable<Company> {
    return this.db.doc$<Company>(`companies/${companyId}`)
  }

  get owner(): boolean {
    return this.claims.owner === true;
  }

  get moderator(): boolean {
    return this.claims.moderator === true;
  }

  /**
   * Retorna o objeto firebase.auth.Auth
   * Usado em algumas situações fora do serviço.
   */
  get auth(): AngularFireAuth {
    return this.afAuth;
  }

  /**
   * Atalho para função na nativa para fazer login e senha do firebase
   */
  emailLogin(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  /**
   * Atualiza campos da usuário na coleção companies, como hora do ultimo login, etc
   */
  updateUserData(user: firebase.User): Promise<void> {
    const userRef = this.db.doc(`users/${user.uid}`);
    return userRef.set(
      {
        displayName: user.displayName,
        photoURL: this.getAvatar(user),
        lastLogin: this.db.timestamp,
        phoneNumber: user.phoneNumber
      },
      { merge: true }
    );
  }


  sendEmailVerification(): Promise<void> {
    // Usar configurações quando tiver aplicativo de celular.
    // const actionCodeSettings = {
    //   url: 'https://www.example.com/cart?email=user@example.com&cartId=123',
    //   iOS: {
    //     bundleId: 'com.example.ios'
    //   },
    //   android: {
    //     packageName: 'com.example.android',
    //     installApp: true,
    //     minimumVersion: '12'
    //   },
    //   handleCodeInApp: true,
    //   dynamicLinkDomain: 'example.page.link'
    // };
    return this.user!.sendEmailVerification();
  }

  disableUser(uid: string): void { }

  /**
   * Este método requer o status de super-administrador (Owner)
   * @param userUid id do usuário
   */
  deleteUser(uid: string): Observable<any> {
    return this.fns.httpsCallable('deleteUser')({ userId: uid });
  }

  /**
   * Faz Logout e redireciona o router para /login
   */
  signOut(): Promise<void> {
    return this.afAuth.signOut().then(() => {
      this.router.navigate(['/login']);
    });
  }

  getUserList(): Observable<any[]> {
    return this.db.col$('users').pipe(map((users: any[]) => {
      return users.map(user => {
        return { ...user, photoURL: this.getAvatar(user) };
      });
    }));
  }

  getAvatar(user: any): string {
    let photo = 'assets/svg/doctor3.svg';
    if (user.photoURL) {
      photo = user.photoURL;
    }
    if (user.title === 'Médico') {
      photo = user.gender === 'M' ? 'assets/svg/doctor3.svg' : 'assets/svg/doctor_f1.svg';
    }
    if (user.title === 'Enfermeiro') {
      photo = user.gender === 'M' ? 'assets/svg/nurse_male.svg' : 'assets/svg/nurse_female.svg';
    }
    if (user.title === 'Fisioterapeuta') {
      photo = 'assets/svg/fisioterapeuta.png';
    }
    return photo;
  }
}
