import { AuthGuard } from './auth/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';

// *******************************************************************************
// Firebase

import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

// *******************************************************************************
// Layouts

import { Layout1Component } from './layout/layout-1/layout-1.component';

// *******************************************************************************
// Pages
import { LoginComponent } from './auth/login/login-component';
import { LayoutBlankComponent } from './layout/layout-blank/layout-blank.component';

// *******************************************************************************
// Routes

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToProtocolos = () => redirectLoggedInTo(['glicemia']);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'glicemia',
    pathMatch: 'full',
  },
  {
    path: 'protocolos',
    redirectTo: 'glicemia', // caminho inicial para a versão antiga
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LayoutBlankComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToProtocolos },
    children: [{ path: '', component: LoginComponent }],
  },
  {
    path: 'glicemia',
    loadChildren: () => import('./glicemia/glicemia.module').then((m) => m.GlicemiaModule),
  },

  {
    path: 'heparina',
    loadChildren: () => import('./heparina/heparina.module').then((m) => m.HeparinaModule),
  },
  // 404 Not Found page
  { path: '**', component: NotFoundComponent },
];

// *******************************************************************************
//

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
