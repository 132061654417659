<div class="authentication-wrapper authentication-1 px-4">
    <div class="authentication-inner py-5">
        <!-- Logo -->
        <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="ui-w-120">
                <div class="w-100">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 174.95 100.98">
            <defs>
              <linearGradient
                id="a"
                data-name="Nova amostra de gradiente 1"
                x1="66.05"
                y1="65.53"
                x2="76.54"
                y2="76.81"
                gradientTransform="matrix(1, 0, 0, -1, 0.01, 100)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stop-color="#1d8bc7" />
                <stop offset="0.75" stop-color="#25b0fb" />
                <stop offset="1" stop-color="#26b3ff" />
              </linearGradient>
              <linearGradient
                id="b"
                x1="107.1"
                y1="14.17"
                x2="117.78"
                y2="24.7"
                gradientTransform="matrix(1, 0, 0, -1, -0.01, 100)"
                xlink:href="#a"
              />
              <linearGradient id="c" x1="71.37" y1="38.04" x2="60.99" y2="28.26" xlink:href="#a" />
            </defs>
            <title>dxmed_logo</title>
            <path
              d="M71.22,34l4.21,5.81.48.66,4.41,6.07v-17l-17.5-19V26.71A40.59,40.59,0,0,1,71.22,34Z"
              transform="translate(-0.01)"
              fill="url(#a)"
            />
            <polygon points="80.33 0 62.8 0 62.8 11.24 80.33 30.48 80.33 0" fill="#26b3ff" />
            <polygon
              points="102.63 77.23 102.63 77.23 102.63 77.24 114.39 93.59 122.32 84.05 110.57 67.45 102.63 77.23"
              fill="url(#b)"
            />
            <polygon
              points="173.73 19.79 149.66 19.79 130.7 45.65 110.02 19.79 83.14 19.79 116.35 60.33 110.1 68.03 121.94 84.51 129.04 75.97 149.66 100.98 174.95 100.98 143.57 60.39 173.73 19.79"
              fill="#26b3ff"
            />
            <path
              d="M60.22,48A23,23,0,0,1,63.8,60.37a25.33,25.33,0,0,1-2.19,9.85l11.07,15A41.56,41.56,0,0,0,78.86,73.9h0L69,60.14Z"
              transform="translate(-0.01)"
              fill="url(#c)"
            />
            <path
              d="M114.4,93.59l-.59-.82L102.63,77.24h0L80.35,46.49l-4.41-6.07-.48-.66L71.22,34a41.23,41.23,0,0,0-8.43-7.39l-.33-.23h0a40.38,40.38,0,0,0-15.94-6.09c-.68-.11-1.38-.19-2.08-.26q-.8-.08-1.59-.12H40.59A40.56,40.56,0,0,0,1.26,50.38,39.56,39.56,0,0,0,0,59.44v1A40.62,40.62,0,0,0,54.22,98.62c.48-.17,1-.35,1.43-.54s1-.39,1.42-.6.93-.43,1.39-.66.92-.46,1.37-.7l1.35-.76c.87-.52,1.73-1.07,2.57-1.66h0a40.86,40.86,0,0,0,9-8.61L61.82,69.79A23.21,23.21,0,0,1,17.52,58v-.2a23.17,23.17,0,0,1,30.3-19.53h0a23.35,23.35,0,0,1,10.8,7.45l1.5,2.09.06.08h0V48L68.93,60.1l9.89,13.76,2.33,3.23,10.1,14h0l7,9.8h10l4.6-5.52Z"
              transform="translate(-0.01)"
              fill="#26b3ff"
            />
          </svg>
                </div>
            </div>
            <h5 class="mt-3">Protocolo de Glicemia</h5>
        </div>
        <!-- / Logo -->

        <!-- Form -->
        <form class="my-3" [formGroup]="myForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label class="form-label">E-mail</label>
                <input formControlName="email" name="email" type="text" class="form-control" />
            </div>

            <div class="form-group">
                <label class="form-label d-flex justify-content-between align-items-end">
          <div>Senha</div>
        </label>
                <input formControlName="password" name="password" type="password" class="form-control" />
            </div>

            <div class="d-flex justify-content-between align-items-center m-0">
                <button type="submit" class="btn btn-primary" [ladda]="loading" data-style="expand-right" [disabled]="!myForm.valid">
          Entrar
        </button>
            </div>

            <div class="d-flex flex-column mt-3" *ngIf="error">
                <ngb-alert type="danger" [dismissible]="false">{{ error.errorMsgPt || error.message }} </ngb-alert>
            </div>
        </form>
        <!-- / Form -->
        <!-- <div class="text-center text-muted"> -->
        <!-- Ainda não tem uma conta? <a href="javascript:void(0)">Registrar</a> -->
        <!-- </div> -->
    </div>
</div>
