import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showLastDiff'
})
export class ShowLastDiffPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    if (!value) {
      return '';
    }
    const signal = (value > 0) ? '+' : '-';
    return `${signal}${value}`;
  }
}
