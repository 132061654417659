// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  emulator: false,
  firebase: {
    apiKey: 'AIzaSyAdpqU2CZTdzFTb-K6qBJAB-E4Epv66TBk',
    authDomain: 'dxmed-app.firebaseapp.com',
    databaseURL: 'https://dxmed-app.firebaseio.com',
    projectId: 'dxmed-app',
    storageBucket: 'dxmed-app.appspot.com',
    messagingSenderId: '571957732690',
    appId: '1:571957732690:web:528c08d1fa23d4de50868b',
    measurementId: 'G-G9CNHPD1HZ'
  }
};
