import { Observable } from 'rxjs';
import { expand, map, mergeMap, take, takeWhile } from 'rxjs/operators';
import { from } from 'rxjs';
import {
  AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore, QueryFn, DocumentChangeType, QueryGroupFn, AngularFirestoreCollectionGroup
} from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type CollectionGroupPredicate<T> = string | AngularFirestoreCollectionGroup<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable({ providedIn: 'root' })
export class FirestoreHelper {

  constructor(public afs: AngularFirestore) { }

  /**
   * É um método que funciona de atalho para chamar um documento do firestore
   * Quando o parametro é uma string ele retorna a referência do documento.
   * Quando o parametro é um a referencia retorna ele mesmo
   */
  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }

  /**
   * @param ref Pode ser uma string ou uma referencia ao documento
   * @returns {Observable<T>} Obsevable gerada a partir do snapshotChanges()
   */
  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(
      map((doc: any) => {
        const id = doc.payload.id;
        return { id, ...doc.payload.data() as T };
      })
    );
  }

  /**
   * É um método que funciona de atalho para chamar uma coleção do firestore
   * Quando o parametro é uma string ele retorna a referência da coleção.
   * Quando o parametro é um a referencia retorna ela mesma.
   */
  col<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref;
  }

  /**
   * @param ref Pode ser uma string ou uma referencia a coleção
   * @param queryFn {QueryFn}
   * @returns {Observable<T>} Obsevable gerada a partir do snapshotChanges() que contém uma array dos documentos na coleção
   */
  col$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn).valueChanges({ idField: 'id' });
  }

  groupCol<T>(ref: CollectionGroupPredicate<T>, queryGroupFn?: QueryGroupFn<T>): AngularFirestoreCollectionGroup<T> {
    return typeof ref === 'string' ? this.afs.collectionGroup<T>(ref, queryGroupFn) : ref;
  }

  groupCol$<T>(ref: CollectionGroupPredicate<T>, queryGroupFn?: QueryGroupFn<T>): Observable<T[]> {
    return this.groupCol<T>(ref, queryGroupFn).valueChanges();
  }

  /**
   * @readonly
   * @returns retorna objeto que será substituído pela data e hora (timestamp) no servidor.
   */
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  increment(x: number) {
    return firebase.firestore.FieldValue.increment(x);
  }

  /**
   * @readonly
   * @returns Retorna o objeto firestore.
   */
  get firestore() {
    return firebase.firestore();
  }

  get batch() {
    return firebase.firestore().batch();
  }

  get fieldValue() {
    return firebase.firestore.FieldValue;
  }

  get serverTimestamp() {
    return firebase.firestore.FieldValue.serverTimestamp;
  }

  colStateChanges$<T>(ref: CollectionPredicate<T>, type: DocumentChangeType[], queryFn?: QueryFn): Observable<any[]> {
    return this.col(ref, queryFn).stateChanges(type).pipe(
      map((actions: any) => {
        return actions.map((a: any) => {
          const data = a.payload.doc.data();
          const oldIndex = a.payload.oldIndex;
          const newIndex = a.payload.oldIndex;
          const id = a.payload.doc.id;
          return { id, oldIndex, newIndex, ...data };
        });
      })
    );
  }

  /**
   * Em teste!
   * Apaga toda uma coleção.
   */
  deleteCollection(path: string, batchSize: number): Observable<any> {
    const source = this.deleteBatch(path, batchSize);
    return source.pipe(
      expand(val => this.deleteBatch(path, batchSize)),
      takeWhile(val => val > 0)
    );
  }

  /**
   * Em teste!
   * Apaga toda uma coleção.
   */
  private deleteBatch(path: string, batchSize: number): Observable<any> {
    const colRef = this.afs.collection(path, ref =>
      ref.orderBy('__name__').limit(batchSize)
    );
    return colRef.snapshotChanges().pipe(
      take(1),
      mergeMap((snapshot: any) => {
        const batch = this.afs.firestore.batch();
        snapshot.forEach((doc: any) => {
          batch.delete(doc.payload.doc.ref);
        });
        return from(batch.commit()).pipe(map(() => snapshot.length));
      })
    );
  }

  enablePersistence() {
    this.firestore.enablePersistence().catch(err => {
      if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });
  }
}
