<nav class="footer" [ngClass]="currentBg()">
    <div class="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
        <div class="pt-3">
            <span class="footer-text font-weight-bolder">DxMed</span> ©
        </div>
        <div>
            <a href="javascript:void(0)" class="footer-link pt-3">Sobre</a>
            <a href="javascript:void(0)" class="footer-link pt-3 ml-4">Ajuda</a>
            <a href="javascript:void(0)" class="footer-link pt-3 ml-4">Contato</a>
            <a href="javascript:void(0)" class="footer-link pt-3 ml-4">Termos &amp; Condições</a>
        </div>
    </div>
</nav>
