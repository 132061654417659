import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { environment } from '../environments/environment'

import localeBr from '@angular/common/locales/pt';
registerLocaleData(localeBr, 'pt');

// *******************************************************************************
// NgBootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// *******************************************************************************
// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, USE_EMULATOR as FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import { AngularFireAuthModule, USE_EMULATOR as AUTH_EMULATOR } from '@angular/fire/auth';
import { AngularFireFunctionsModule, USE_EMULATOR as FUNCTIONS_EMULATOR } from '@angular/fire/functions';
import { AngularFirePerformanceModule } from '@angular/fire/performance';

// *******************************************************************************
// App

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AppService } from './app.service';
import { LayoutModule } from './layout/layout.module';

// *******************************************************************************
// Libs
import { LaddaModule } from 'angular2-ladda';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

// *******************************************************************************
// Pages

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './auth/login/login-component';
import { SharedModule } from './shared/shared.module';

// *******************************************************************************
//

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,

    // Auth
    LoginComponent,
  ],

  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,

    // Libs
    LaddaModule,
    SweetAlert2Module.forRoot(),

    // Firebase
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFirePerformanceModule,

    // App
    AppRoutingModule,
    LayoutModule,
    SharedModule,
  ],
  providers: [
    Title,
    AppService,
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: AUTH_EMULATOR,
      useValue: environment.emulator ? ['localhost', 9099] : undefined,
    },
    {
      provide: FIRESTORE_EMULATOR,
      useValue: environment.emulator ? ['localhost', 8080] : undefined,
    },
    {
      provide: FUNCTIONS_EMULATOR,
      useValue: environment.emulator ? ['localhost', 5001] : undefined,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
