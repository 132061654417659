<nav class="navbar navbar-expand-lg align-items-lg-center container-p-x" [ngClass]="currentBg()">

    <!-- Brand demo (see demo.css) -->
    <a routerLink="/" class="navbar-brand app-brand demo d-lg-none py-0 mr-4">
        <span class="app-brand-logo demo bg-primary">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 174.96 100.98">
        <defs>
          <linearGradient id="a" data-name="Nova amostra de gradiente 1" x1="66.04" y1="34.48" x2="76.54" y2="23.18"
            gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#bfbfbf" />
            <stop offset="0.75" stop-color="#e6e6e6" />
            <stop offset="1" stop-color="#fff" />
          </linearGradient>
          <linearGradient id="b" x1="107.11" y1="85.83" x2="117.79" y2="75.3" xlink:href="#a" />
          <linearGradient id="c" x1="71.37" y1="61.96" x2="60.99" y2="71.74" xlink:href="#a" />
        </defs>
        <title>dxmed_logo-white</title>
        <path d="M71.21,34l4.21,5.81.48.66,4.41,6.07v-17l-17.5-19V26.71A40.59,40.59,0,0,1,71.21,34Z"
          transform="translate(0.01)" fill="url(#a)" />
        <polygon points="80.34 0 62.81 0 62.81 11.24 80.34 30.48 80.34 0" fill="#f9f9f9" />
        <polygon points="102.64 77.23 102.64 77.23 102.64 77.24 114.4 93.59 122.33 84.05 110.58 67.45 102.64 77.23"
          fill="url(#b)" />
        <polygon
          points="173.74 19.79 149.67 19.79 130.71 45.65 110.03 19.79 83.15 19.79 116.36 60.33 110.11 68.03 121.95 84.51 129.05 75.97 149.67 100.98 174.96 100.98 143.58 60.39 173.74 19.79"
          fill="#f9f9f9" />
        <path
          d="M60.21,48a23,23,0,0,1,3.58,12.37,25.33,25.33,0,0,1-2.19,9.85l11.07,15A41.56,41.56,0,0,0,78.85,73.9h0L69,60.14Z"
          transform="translate(0.01)" fill="url(#c)" />
        <path
          d="M114.39,93.59l-.59-.82L102.62,77.24h0L80.34,46.49l-4.41-6.07-.48-.66L71.21,34a41.23,41.23,0,0,0-8.43-7.39l-.33-.23h0a40.38,40.38,0,0,0-15.94-6.09c-.68-.11-1.38-.19-2.08-.26q-.8-.08-1.59-.12H40.58A40.56,40.56,0,0,0,1.25,50.38,39.58,39.58,0,0,0,0,59.44v1A40.63,40.63,0,0,0,54.21,98.62c.48-.17,1-.35,1.43-.54s1-.39,1.42-.6.93-.43,1.39-.66.92-.46,1.37-.7l1.35-.76c.87-.52,1.73-1.07,2.57-1.66h0a40.86,40.86,0,0,0,9-8.61L61.81,69.79A23.21,23.21,0,0,1,17.51,58v-.2A23.18,23.18,0,0,1,43,37.23a23.8,23.8,0,0,1,4.79,1h0a23.35,23.35,0,0,1,10.8,7.45l1.5,2.09.06.08h0V48l8.75,12.1,9.89,13.76,2.33,3.23,10.1,14h0l7,9.8h10l4.6-5.52Z"
          transform="translate(0.01)" fill="#f9f9f9" />
      </svg>
    </span>
        <span class="app-brand-text demo font-weight-normal ml-2">DxMed</span>
    </a>

    <!-- Sidenav toggle (see demo.css) -->
    <div class="layout-sidenav-toggle navbar-nav d-lg-none align-items-lg-center mr-auto" *ngIf="sidenavToggle">
        <a class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)" (click)="toggleSidenav()">
            <i class="ion ion-md-menu text-large align-middle"></i>
        </a>
    </div>

    <!-- Navbar toggle -->
    <button class="navbar-toggler" type="button" (click)="isExpanded = !isExpanded">
    <span class="navbar-toggler-icon"></span>
  </button>

    <div class="navbar-collapse collapse" [ngbCollapse]="!isExpanded">

        <!-- Divider -->
        <hr class="d-lg-none w-100 my-2">

        <div class="navbar-nav align-items-lg-center">
            <!-- Search -->
            <label class="nav-item navbar-text navbar-search-box p-0 active">
        <span class="navbar-search-input pl-2">
          {{ auth.currentCompany?.title }}
        </span>
      </label>
        </div>

        <div class="navbar-nav align-items-lg-center ml-auto">

            <div ngbDropdown [placement]="isRTL ? 'bottom-left' : 'bottom-right'" class="demo-navbar-user nav-item">
                <a ngbDropdownToggle class="nav-link" href="javascript:void(0)">
                    <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
            <!-- <img src="assets/img/avatars/1.png" alt class="d-block ui-w-30 rounded-circle"> -->
            <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ auth.user?.email }}</span>
                    </span>
                </a>
                <div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">
                    <a href="javascript:void(0)" class="dropdown-item" (click)="auth.signOut()"><i
              class="ion ion-ios-log-out text-danger"></i> &nbsp; Log
            Out</a>
                </div>
            </div>
        </div>

    </div>

</nav>